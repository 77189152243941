@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&display=swap');

input:disabled {
    opacity: 0.6;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 500000000000000s ease-in-out 500000000000000s,
        color 500000000000000s ease-in-out 500000000000000s;
}
button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    padding: 0;
}
.with-14-force {
    width: 2rem !important;
}

.toast-shadow {
    -webkit-box-shadow: 0 0 10px 1px #94a3b8;
    -moz-box-shadow: 0 0 10px 1px #94a3b8;
    box-shadow: 0 0 10px 1px #94a3b8;
}
.dark [type='checkbox']:checked,
.dark [type='radio']:checked,
[type='checkbox']:checked,
[type='radio']:checked {
    background-color: currentColor;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-color: transparent;
}
[type='checkbox']:checked {
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMTYgMTYnIGZpbGw9JyNmZmYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEyLjIwNyA0Ljc5M2ExIDEgMCAwIDEgMCAxLjQxNGwtNSA1YTEgMSAwIDAgMS0xLjQxNCAwbC0yLTJhMSAxIDAgMCAxIDEuNDE0LTEuNDE0TDYuNSA5LjA4Nmw0LjI5My00LjI5M2ExIDEgMCAwIDEgMS40MTQgMHonLz48L3N2Zz4=);
}
[type='checkbox'],
[type='radio'] {
    print-color-adjust: exact;
    --tw-shadow: 0 0 #0000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-origin: border-box;
    border-color: #6b7280;
    border-width: 1px;
    color: #1c64f2;
    display: inline-block;
    flex-shrink: 0;
    height: 1rem;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 1rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

*:focus {
    outline: 0px none transparent;
}
body {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
        sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
}

.body {
    background: linear-gradient(
        238deg,
        theme('colors.purple.600'),
        theme('colors.cyan.400'),
        theme('colors.green.400'),
        theme('colors.blue.500'),
        theme('colors.orange.300'),
        theme('colors.pink.400'),
        theme('colors.cyan.400'),
        theme('colors.teal.400'),
        theme('colors.lime.500')
    );
    background-size: 2000% 2000%;
    -webkit-animation: rainbow 40s ease infinite;
    animation: rainbow 40s ease infinite;
    background-clip: text;
}
@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 100%;
    }
}
@keyframes rainbow {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.react-date-picker__wrapper {
    border-width: 0px !important;
}

.item-vendor {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
}

.flex-1-full {
    flex: 1 0 auto;
}

.beautiful {
    background: linear-gradient(
        124deg,
        #ff2400,
        #e81d1d,
        #e8b71d,
        #e3e81d,
        #1de840,
        #1ddde8,
        #2b1de8,
        #dd00f3,
        #dd00f3
    );
    background-size: 1800% 1800%;

    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;
}

@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%;
    }
    50% {
        background-position: 100% 19%;
    }
    100% {
        background-position: 0% 82%;
    }
}
@-moz-keyframes rainbow {
    0% {
        background-position: 0% 82%;
    }
    50% {
        background-position: 100% 19%;
    }
    100% {
        background-position: 0% 82%;
    }
}
@-o-keyframes rainbow {
    0% {
        background-position: 0% 82%;
    }
    50% {
        background-position: 100% 19%;
    }
    100% {
        background-position: 0% 82%;
    }
}
@keyframes rainbow {
    0% {
        background-position: 0% 82%;
    }
    50% {
        background-position: 100% 19%;
    }
    100% {
        background-position: 0% 82%;
    }
}

#root {
    height: 100vh;
    width: 100vw;
}
